$primary: #1b745e;
$light: rgb(208, 223, 207);
$dark: #0b5744;
$offwhite: #fcfcfc;

@import "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Raleway:wght@500&display=swap');

* {
  font-family: 'Raleway', Helvetica, sans-serif;
}

html,
body {
  color: $primary;
  background-color: $offwhite;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Quicksand', Helvetica, sans-serif;
  color: $dark;
}

a {
  color: $primary;

  &:visited {
    color: $primary;
  }

  &:hover {
    color: #{shade-color($primary, 10%)};
  }
}

header {
  text-align: center;
  background: url(./images/green-header.jpg);
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 200px;
  background-position: center bottom;

  @include media-breakpoint-down(md) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  img {
    width: 90%;
    max-width: 600px;

    @include media-breakpoint-down(md) {
      max-width: 400px;
    }
  }

  nav {
    color: $offwhite;

    background-color: #{shade-color($dark, 20%)};
    position: fixed;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    z-index: 10;

    a {
      color: $offwhite !important;
      text-decoration: none;
      padding: 3px 10px;
      border-radius: 5px;

      &:hover {
        background: $offwhite;
        color: #{shade-color($dark, 20%)} !important;
      }

    }
  }
}

.intro {
  text-align: center;

  h1 {
    font-size: 5rem;
    margin-top: 2rem;
    margin-bottom: 4rem;

    @include media-breakpoint-down(l) {
      font-size: 4.5rem;
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 4rem;
    }
  }

  p {
    text-align: left;
    font-size: 1rem;
    line-height: 1.7;
    letter-spacing: 0.1rem;
  }
}

.feature,
.gallery,
.green {
  background-color: $light;
}

.feature {
  img {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    .row:nth-of-type(even) div:nth-child(1) {
      order: 1;
    }
  }

  @include media-breakpoint-down(md) {
    .row {
      padding-top: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.gallery {
  @include media-breakpoint-up(md) {
    padding: 5rem 0;
  }

  img {
    width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .pt-10 {
    padding: 0 10% !important;
  }
}

figure iframe {
  width: 100%;
  height: 100vh;
  max-height: 400px;
}

figcaption {
  font-size: 0.8rem;
  text-align: right;
}

footer {
  .hstack {
    a {

      img,
      svg {
        height: 30px;
        width: 30px;
      }

      &:hover {
        position: relative;
        top: -5px;
      }
    }
  }
}

.footer-image {
  opacity: 0.3;
}